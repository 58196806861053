html {
    height: 100%;
    font-size: 62.5%;
}


body {
    background-color: #EAECED;
    color: #2B363B;
    font-size: 0;
    height: 100%;
    margin: 0;
    padding: 0;
}


.logo {
    color: #3A7F93;
    font-family: 'Jomhuria', cursive;
    font-size: 3.2rem;
    font-weight: 400;
    left: 0;
    margin: 0;
    padding: 2rem;
    position: sticky;
    text-decoration: none;
    top: 0;
}


#phrase {
    font-family: 'DM Serif Display', serif;
    font-size: 6vw;
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}